import React from 'react'
import ContactForm from './ContactForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBuilding,
  faEnvelope,
  faPhone,
} from '@fortawesome/pro-solid-svg-icons'

const Contact = ({ data }) => {
  const currentTimeUTC = new Date().getUTCHours()

  const isWithinWorkingHours =
    currentTimeUTC >= data.contact.workTimeUTC.from &&
    currentTimeUTC < data.contact.workTimeUTC.to

  return (
    <div>
      <section id="contact-intro" className="section contact">
        <div className="container" style={{ marginTop: '150px' }}>
          <h1 className="text-center mb-5">We praten graag met je</h1>
          <div className="row d-none d-md-block">
            <div className="contact-form-area">
              <ContactForm
                isStatic
                reCaptchaSiteKey={process.env.GATSBY_RECAPTCHA_SITE_KEY_V3}
                mailingUrl={`${process.env.GATSBY_MAILING_URL}/api/contact`}
              />
            </div>
          </div>
        </div>
      </section>
      <section id="contact-data" className="section bg-dark bg-dark">
        <div className="container">
          <div className="row">
            <div className="col-md-12 offset-md-0 col-lg-8 offset-lg-2">
              <div className="row">
                <div className="col-sm-8 offset-sm-2 mb-5">
                  <h3>
                    <span className="text-primary">Hoofdkwartier</span>
                    <br />
                    {data.address.office.city}
                  </h3>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <ul className="fa-ul">
                        <li>
                          <span className="fa-li">
                            <FontAwesomeIcon icon={faEnvelope} />
                          </span>
                          <a
                            href={`mailto:${data.contact.email}`}
                            className="link-unstyled"
                          >
                            {data.contact.email}
                          </a>
                        </li>
                        <li>
                          <span className="fa-li">
                            <FontAwesomeIcon
                              icon={faPhone}
                              style={
                                isWithinWorkingHours
                                  ? { color: '#27ae60' }
                                  : {
                                      color: '#c0392b',
                                      transform: 'rotate(-135deg)',
                                    }
                              }
                            />
                          </span>{' '}
                          <a
                            href={`tel:${data.contact.phoneClean}`}
                            className="link-unstyled"
                          >
                            {data.contact.phone}
                          </a>
                          <p
                            className="text-sm"
                            style={{ lineHeight: 1, marginTop: '-3px' }}
                          >
                            <small>GMT+1 (Mo-Fr 9am-5pm)</small>
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-6">
                      <ul className="fa-ul">
                        <li>
                          <span className="fa-li">
                            <FontAwesomeIcon icon={faBuilding} />
                          </span>
                          <a
                            className="link-unstyled"
                            href={data.address.office.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <address className="text-sm">
                              {data.address.office.street}
                              <br />
                              {`${data.address.office.postal} ${data.address.office.city}`}
                              <br />
                              {data.address.office.country}
                            </address>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Contact
